import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | K13 Marketing Agency
			</title>
			<meta name={"description"} content={"Heben Sie Ihre Marke auf ein neues Niveau"} />
			<meta property={"og:title"} content={"Home | K13 Marketing Agency"} />
			<meta property={"og:description"} content={"Heben Sie Ihre Marke auf ein neues Niveau"} />
			<meta property={"og:image"} content={"https://glaricorn.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://glaricorn.com/img/43658675681.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glaricorn.com/img/43658675681.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glaricorn.com/img/43658675681.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glaricorn.com/img/43658675681.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glaricorn.com/img/43658675681.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glaricorn.com/img/43658675681.png"} />
			<meta name={"msapplication-TileColor"} content={"https://glaricorn.com/img/43658675681.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0px 0 0px 0" background="--color-primary" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					K13 Marketing
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Willkommen bei K13, wo wir Ihre Marketingbemühungen auf ein neues Niveau heben. Unser engagiertes Expertenteam ist bestrebt, innovative, datengesteuerte Strategien zu entwickeln, die Ihr Unternehmen transformieren und herausragende Ergebnisse erzielen. Begleiten Sie uns auf einer Reise, um Ihre Marke aufzuwerten und beispiellosen Erfolg zu erzielen.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--light"
						background="--color-dark"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="transparent"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Kontakte
					</Link>
					<Link
						href="/services"
						padding="10px 24px 10px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-light"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						sm-width="100%"
						hover-border-color="--color-dark"
						hover-color="--dark"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
					>
						Dienstleistungen
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://glaricorn.com/img/1.jpg"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 33px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="#ffffff"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Warum K13 wählen?
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="1fr"
				justify-items="center"
			>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
					Expertenteam
					</Text>
					<Text margin="0px" color="--dark" text-align="center" font="--lead">
					Unser Team aus Marketingfachleuten bringt umfassendes Wissen und Erfahrung mit, um außergewöhnlichen Service und außergewöhnliche Ergebnisse zu liefern.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
					position="relative"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
					Maßgeschneiderte Lösungen
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="center"
						font="--lead"
						flex="1 1 auto"
					>
						Wir passen unsere Strategien an Ihre individuellen Geschäftsanforderungen an und sorgen so für die bestmöglichen Ergebnisse.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
					Innovativer Ansatz
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="center"
						font="--lead"
						flex="1 1 auto"
					>
						Wir bleiben immer einen Schritt voraus, indem wir die neuesten Marketingtrends und -technologien implementieren, um die Wettbewerbsfähigkeit Ihrer Marke zu erhalten.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section text-align="center" color="--light" padding="80px 0" sm-padding="40px 0">
			<Text
				as="h1"
				font="--headline1"
				color="--primary"
				md-font="--headline2"
				margin="10px 0 0 0"
			>
				Über K13
			</Text>
			<Text
				as="p"
				font="--lead"
				color="--dark"
				margin="40px 0 20px 0"
				text-align="left"
			>
				Bei K13 verstehen wir die transformative Kraft effektiven Marketings. Unsere Agentur basiert auf Kreativität, Fachwissen und einem unermüdlichen Streben, Spitzenleistungen für unsere Kunden zu erzielen. Mit umfassender Erfahrung in verschiedenen Branchen sind wir in der Lage, Unternehmen jeder Größe zu betreuen und ihnen zu helfen, bei ihren Marketingbemühungen neue Höhen zu erreichen.
				<br/><br/>
				Unser Ansatz kombiniert traditionelle Marketingprinzipien mit hochmodernen digitalen Trends und gewährleistet so eine ganzheitliche und effektive Strategie für jeden Kunden. Wir wissen, dass jedes Unternehmen einzigartig ist, weshalb wir unsere Dienstleistungen auf Ihre spezifischen Ziele und Bedürfnisse zuschneiden. Ob Sie Ihre Onlinepräsenz verbessern, ein neues Produkt auf den Markt bringen oder effektiver mit Ihrem Publikum interagieren möchten, K13 hat die nötige Expertise, um dies umzusetzen.
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="center"
					md-text-align="center"
					sm-text-align="left"
				>
					Fangen wir an
				</Text>
				<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Sind Sie bereit, Ihr Marketing auf ein neues Niveau zu heben? Kontaktieren Sie K13 noch heute und entdecken Sie den Unterschied, den unsere Expertise ausmachen kann. Lassen Sie uns diese Reise gemeinsam antreten, Ihre Marke auf ein neues Niveau heben und beispiellosen Erfolg erzielen.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});